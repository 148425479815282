/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(({ functions, borders }) => {
  const { pxToRem } = functions;
  const { borderRadius } = borders;

  return {
    coverLayout: {
      minHeight: "75vh",
      margin: 0,
    },

    coverLayout_imageBox: {
      height: "100%",
      overflow: "hidden",
      marginRight: pxToRem(-200),
      marginTop: pxToRem(80),
      borderBottomLeftRadius: borderRadius.lg,
    },

    coverLayout_image: {
      backgroundImage: ({ image }) => `url(${image})`,
      backgroundSize: "cover",
      transform: "skewX(1deg)",
      marginLeft: pxToRem(11),
      height: "21%",
    },
    text_align: {
      top: "auto",
      bottom: 0,
      marginLeft: pxToRem(20),
    },
    bg_image: {
      backgroundImage: ({ bg_image }) => {
        return `url(${bg_image})`;
      },
      backgroundSize: "cover",
      marginLeft: pxToRem(-10),
      marginTop: pxToRem(-50),
      marginBottom: pxToRem(30),
      height: "25%",
      width: "38%",
    },
    image: {
      backgroundImage: ({ image }) => {
        return `url(${image})`;
      },
      backgroundPosition: "left",
      backgroundSize: 230,
      backgroundRepeat: "no-repeat",
      marginLeft: pxToRem(0),
      marginTop: pxToRem(-70),
      marginBottom: pxToRem(-200),
      height: "35%",
      width: "90%",
    },
  };
});
