/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
//import BasicLayout from "layouts/authentication/components/BasicLayout";
import CoverLayout from "layouts/authentication/components/CoverLayout";
//import Socials from "layouts/authentication/components/Socials";
//import Separator from "layouts/authentication/components/Separator";

// Images
//import curved6 from "assets/images/curved-images/curved14.jpg";
import logo_full from "assets/images/logo_full.png";
import image_bg from "assets/images/logos/uptickpro.jpg";

import AuthApi from "../../../api/auth";
//import { useHistory } from "react-router-dom";

function ForgotPwd() {
  //  const history = useHistory();
  const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState("Reset Password");
  const [error, setError] = useState({});

  const submit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (email === "") {
      return setError({ message: "You must enter your email.", color: "red" });
    } else {
      var emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let isvalid = emailPattern.test(email);
      if (!isvalid) {
        return setError({ message: "Enter a valid email address.", color: "red" });
      }
    }
    setButtonText("sending mail");
    try {
      await AuthApi.Resetpwd({
        email,
      });
      setButtonText("Reset Password");
      return setError({
        message:
          "If the provided email address matches our records, we’ll send you a link to reset your password.",
        color: "primary",
      });
    } catch (err) {
      setButtonText("Reset Password");
      return setError({
        message:
          "If the provided email address matches our records, we’ll send you a link to reset your password.",
        color: "primary",
      });
    }
  };

  return (
    <CoverLayout
      title="Reset your password"
      description="Enter your registered email address."
      image={logo_full}
      bg_image={image_bg}
      points={`Be RISC-V Ready with Vyoma's UpTickPro`}
      points2={`Accelerate your RISC-V product to market using ready-to-deploy`}
      points3={`RISC-V Verification, Emulation, Software ecosystem solutions`}
    >
      <Card>
        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiInput
                onChange={(event) => {
                  setEmail(event.target.value);
                  setError({});
                }}
                placeholder="Email"
              />
            </SuiBox>
            <SuiBox mt={2} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".8em",
                  color: error.color,
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error.message}
              </h6>
            </SuiBox>
            <SuiBox mt={4} mb={1}>
              <SuiButton onClick={submit} variant="gradient" buttonColor="dark" fullWidth>
                {buttonText}
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </CoverLayout>
  );
}

export default ForgotPwd;
