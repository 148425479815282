/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import { Visibility, VisibilityOff } from "@mui/icons-material";
//import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../../auth-context/auth.context";
import AuthApi from "../../../../api/auth";

function ChangePassword() {
  const { setUser } = useAuth();
  const history = useHistory();
  let { user } = useAuth();

  const [password, setPassword] = useState("");
  const [cfmPassword, setCfmPassword] = useState("");
  const [error, setError] = useState({});
  const [buttonText, setButtonText] = useState("Submit");
  const [visibility, setVisibility] = useState(false);
  const [confrmVisibility, setConfirmVisibility] = useState(false);

  const visibilityChange = () => {
    //alert("changed the visibility");
    setVisibility(!visibility);
  };

  const visibilityConfrmChange = () => {
    //alert("changed the visibility");
    setConfirmVisibility(!confrmVisibility);
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    //console.log("Button is pressed");
    if (password === "") {
      return setError({ message: "You must enter your new password.", color: "red" });
    }
    if (cfmPassword === "") {
      return setError({ message: "You must enter confirm password", color: "red" });
    }
    if (cfmPassword !== password) {
      return setError({ message: "New password and Confirm password must match.", color: "red" });
    }
    setButtonText("Changing password");
    try {
      let response = await AuthApi.ChangePwd({
        password,
        user,
      });
      if (response.data && response.data.success === true) {
        setError({ message: response.data.msg, color: "green" });
      }
      setUser(null);
      localStorage.removeItem("user");
      history.push({
        pathname: "/authentication/sign-in",
        state: { key: "Password changed successfully. Login again with new password!!!" },
      });
    } catch (err) {
      console.log(err);
      if (typeof err.response !== "undefined" && err.response.status == 400) {
        setError({ message: err.response.data.password.msg, color: "red" });
      } else if (typeof err.response !== "undefined" && err.response.status == 403) {
        setUser(null);
        localStorage.removeItem("user");
        history.push("/authentication/sign-in");
      }
    }
    setButtonText("Submit");
  };
  return (
    <Card>
      <SuiBox pt={2} px={2}>
        <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Change Password
        </SuiTypography>
      </SuiBox>
      <SuiBox pt={3} pb={3} px={3}>
        <SuiBox component="form" role="form">
          <SuiBox mb={2}>
            <SuiInput
              defaultValue={password}
              type={visibility ? "text" : "password"}
              placeholder="New Password"
              withIcon={{
                icon: visibility ? <VisibilityOff /> : <Visibility />,
                direction: "right",
              }}
              onChange={(event) => {
                setPassword(event.target.value);
                setError({});
              }}
              handleIconClick={visibilityChange}
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiInput
              defaultValue={cfmPassword}
              type={confrmVisibility ? "text" : "password"}
              placeholder="Confirm Password"
              withIcon={{
                icon: confrmVisibility ? <VisibilityOff /> : <Visibility />,
                direction: "right",
              }}
              onChange={(event) => {
                setCfmPassword(event.target.value);
                setError({});
              }}
              handleIconClick={visibilityConfrmChange}
            />
          </SuiBox>
          <SuiBox mb={2} textAlign="center">
            <h6
              style={{
                fontSize: ".8em",
                color: error.color,
                textAlign: "center",
                fontWeight: 400,
                transition: ".2s all",
              }}
            >
              {error.message}
            </h6>
          </SuiBox>
          <SuiBox mt={4} mb={1}>
            <SuiButton variant="gradient" buttonColor="dark" fullWidth onClick={handleSubmit}>
              {buttonText}
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

export default ChangePassword;
