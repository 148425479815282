/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { forwardRef } from "react";
//import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
//import Card from "@mui/material/Card";
//import Icon from "@mui/material/Icon";
//import Menu from "@mui/material/Menu";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import Divider from "@mui/material/Divider";
//import Grid from "@mui/material/Grid";

// Custom styles for the NotificationItem
import styles from "examples/PricingItemCard/styles";
//import wavesWhite from "assets/images/shapes/waves-white.svg";
//import rocketWhite from "assets/images/illustrations/rocket-white.png";

const PricingItemCard = forwardRef(
  (
    {
      image,
      color,
      heading,
      title,
      points,
      addons,
      onRequest,
      buttonName,
      price,
      buttonColor,
      onInfoRequest,
      menuInfo,
      ...rest
    },
    ref
  ) => {
    const classes = styles({ color });

    return (
      <MenuItem {...rest} ref={ref} className={classes.pricingItem}>
        <SuiBox>
          <SuiBox>
            <SuiBox customClass={classes.pricingItem_img}>{image}</SuiBox>
          </SuiBox>
          <SuiBox pt={1} mb={0.5}>
            <SuiTypography variant="h4" textColor="text" fontWeight="bold">
              {heading}
            </SuiTypography>
          </SuiBox>
          {price && (
            <>
              <SuiBox pt={1} mb={0.12} display="flex">
                <SuiTypography variant="h6" textColor="primary" fontWeight="bold">
                  {price}
                </SuiTypography>
                <SuiTypography variant="caption" textColor="primary" p={0.75}>
                  per month (incl Tax)
                </SuiTypography>
                <SuiTypography textColor="primary">
                  <InfoOutlinedIcon
                    className="font-bold"
                    onMouseEnter={(event) => onInfoRequest(event)}
                  />
                </SuiTypography>
              </SuiBox>
              {menuInfo()}
            </>
          )}
          <Divider />
          <SuiTypography variant="h5" fontWeight="bold">
            {title}
          </SuiTypography>
          <SuiBox p={2}>
            {points &&
              points.map((point, index) => (
                <SuiBox key={index} mb={1} lineHeight={1}>
                  <SuiTypography variant="button" textColor="text" fontWeight="regular">
                    * {point}
                  </SuiTypography>
                </SuiBox>
              ))}
          </SuiBox>
          <Divider />
          <SuiTypography variant="h5" fontWeight="bold">
            Paid Add-Ons
          </SuiTypography>
          <SuiBox p={2}>
            {addons &&
              addons.map((addon, index) => (
                <SuiBox key={index} mb={1} lineHeight={1}>
                  <SuiTypography variant="button" textColor="text" fontWeight="regular">
                    * {addon}
                  </SuiTypography>
                </SuiBox>
              ))}
          </SuiBox>
          <SuiTypography
            component="a"
            href="#"
            variant="button"
            textColor="text"
            fontWeight="medium"
            customClass={classes.pricingItem_button}
            onClick={() => onRequest()}
          >
            <SuiButton variant="gradient" buttonColor={buttonColor}>
              {buttonName}
            </SuiButton>
          </SuiTypography>
        </SuiBox>
      </MenuItem>
    );
  }
);

// Setting default values for the props of NotificationItem
PricingItemCard.defaultProps = {
  color: "dark",
  buttonColor: "info",
};

// Typechecking props for the NotificationItem
PricingItemCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired,
  //  date: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(PropTypes.string).isRequired,
  addons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRequest: PropTypes.func.isRequired,
  onInfoRequest: PropTypes.func,
  menuInfo: PropTypes.func,
  price: PropTypes.string,
  buttonColor: PropTypes.string,
};
PricingItemCard.displayName = "PricingItemCard";
export default PricingItemCard;
