/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

export default {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};
