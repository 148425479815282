/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

// @mui material components
import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette, borders, transitions, functions }) => {
  const { light, secondary } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    pricingItem: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      color: secondary.main,
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      transition: transitions.create("background-color", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),

      "&:not(:last-child)": {
        marginBottom: pxToRem(10),
      },

      "&:hover": {
        backgroundColor: light.main,
      },
    },

    pricingItem_img: {
      display: "grid",
      width: pxToRem(50),
      height: pxToRem(50),
      //      margin: `${pxToRem(2)} ${pxToRem(16)} ${pxToRem(2)} 0`,
      margin: `${pxToRem(1)} ${pxToRem(80)}`,
      //      width: `calc(100% - ${pxToRem(48)})`,
      borderRadius: borderRadius.lg,

      "& img": {
        width: "100%",
        borderRadius: borderRadius.lg,
      },
    },

    notificationItem_date: {
      display: "flex",
      alignItems: "center",
      marginTop: pxToRem(4),
    },

    notificationItem_icon: {
      lineHeight: 1.2,
      marginRight: pxToRem(4),
    },
    pricingItem_button: {
      marginTop: "auto",
      marginRight: "auto",
      display: "inline-flex",
      alignItems: "center",
      cursor: "pointer",

      "& .material-icons-round": {
        fontSize: "1.125rem",
        transform: `translate(${pxToRem(2)}, ${pxToRem(-1)})`,
        transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
      },

      "&:hover .material-icons-round, &:focus  .material-icons-round": {
        transform: `translate(${pxToRem(6)}, ${pxToRem(-1)})`,
      },
    },
  };
});
