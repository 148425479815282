/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/NotificationItem";

// Custom styles for DashboardNavbar
import styles from "examples/Navbars/DashboardNavbar/styles";
import iclassImg from "assets/images/iclass-img.png";

// Soft UI Dashboard React context
import { useSoftUIController } from "context";

// Images
//import team2 from "assets/images/team-2.jpg";
//import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import { useAuth } from "../../../auth-context/auth.context";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const classes = styles({ transparentNavbar, absolute, light, isMini });
  const route = useLocation().pathname.split("/").slice(1);
  const { user } = useAuth();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      dispatch({
        type: "TRANSPARENT_NAVBAR",
        value: (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      });
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  //  const handleConfiguratorOpen = () =>
  //    dispatch({ type: "OPEN_CONFIGURATOR", value: !openConfigurator });
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      style={{ marginTop: "1rem" }}
    >
      <NotificationItem title={[user.username]} date={user.email} onClick={handleCloseMenu} />
      <Divider component="li" flexItem />
      {
        //      <SuiTypography
        //        variant="caption"
        //        fontWeight="bold"
        //        textTransform="uppercase"
        //        customClass={classes.sidenav_title}
        //      >
        //        Account
        //      </SuiTypography>
      }
      <SuiBox>
        <NavLink to="/profile" key="profile">
          <SuiTypography
            variant="h8"
            textColor="secondary"
            customClass={classes.notificationItem_date}
          >
            Change password
          </SuiTypography>
        </NavLink>
      </SuiBox>
      <SuiBox>
        <NavLink to="/authentication/sign-out" key="sign-out">
          <SuiTypography
            variant="h8"
            textColor="secondary"
            customClass={classes.notificationItem_date}
          >
            Logout
          </SuiTypography>
        </NavLink>
      </SuiBox>
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      className={classes.navbar}
    >
      <Toolbar className={classes.navbar_container}>
        <SuiBox customClass={classes.navbar_row} color="inherit" mb={{ xs: 1, md: 0 }}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SuiBox>
        {isMini ? null : (
          <SuiBox customClass={classes.navbar_row}>
            <SuiBox
              color={light ? "white" : "inherit"}
              customClass={classes.navbar_section_desktop}
            >
              <SuiAvatar
                src={iclassImg}
                size="sm"
                variant="rounded"
                onClick={handleOpenMenu}
                customClass={classes.img_cursor}
              />
              {renderMenu()}
            </SuiBox>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
