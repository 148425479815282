/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

export default {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};
