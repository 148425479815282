/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React example components
//import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Custom styles for the Baise
import styles from "layouts/authentication/components/CoverLayout/styles";

// Soft UI Dashboard React page layout routes
//import routes from "routes";
//import { useAuth } from "../../../../auth-context/auth.context";

function CoverLayout({
  color,
  header,
  title,
  description,
  image,
  bg_image,
  children,
  points,
  points2,
  points3,
}) {
  const classes = styles({ image, bg_image });
  //  let { routes } = useAuth();

  return (
    <PageLayout background="white">
      <Grid container justifyContent="left">
        <Grid item xs={5} sm={2} md={2} xl={2} />
        <Grid item xs={12} sm={8} md={5} xl={3}>
          <SuiBox customClass={classes.image} py={14} px={0}>
            <SuiBox customClass={classes.image} />
          </SuiBox>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" className={classes.coverLayout}>
        <Grid item xs={12} md={5}>
          <SuiBox mb={10} mt={20} position="relative" right={{ md: "0rem", xl: "0rem" }}>
            <SuiBox customClass={classes.bg_image} py={19} px={18} />
            <SuiTypography variant="h2" textColor="dark" customClass={classes.text_align}>
              {points}
            </SuiTypography>
            <SuiTypography variant="h5" textColor="landinfo" customClass={classes.text_align}>
              {points2}
            </SuiTypography>
            <SuiTypography variant="h5" textColor="landinfo" customClass={classes.text_align}>
              {points3}
            </SuiTypography>
          </SuiBox>
        </Grid>
        <Grid item xs={12} sm={8} md={5} xl={3}>
          <SuiBox mt={11}>
            <SuiBox pt={3} px={3}>
              {!header ? (
                <>
                  <SuiBox mb={1}>
                    <SuiTypography variant="h3" fontWeight="bold" textColor={color} textGradient>
                      {title}
                    </SuiTypography>
                  </SuiBox>
                  <SuiTypography variant="body2" fontWeight="regular" textColor="text">
                    {description}
                  </SuiTypography>
                </>
              ) : (
                header
              )}
            </SuiBox>
            <SuiBox p={3}>{children}</SuiBox>
          </SuiBox>
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  );
}

// Setting default values for the props of CoverLayout
CoverLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 20,
};

// Typechecking props for the CoverLayout
CoverLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "landinfo",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  points: PropTypes.string,
  points2: PropTypes.string,
  points3: PropTypes.string,
  image: PropTypes.string.isRequired,
  bg_image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
