/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

import { useState } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
//import BasicLayout from "layouts/authentication/components/BasicLayout";
import CoverLayout from "layouts/authentication/components/CoverLayout";
//import Socials from "layouts/authentication/components/Socials";
//import Separator from "layouts/authentication/components/Separator";

// Images
//import curved6 from "assets/images/curved-images/curved14.jpg";
import logo_full from "assets/images/logo_full.png";
import image_bg from "assets/images/logos/uptickpro.jpg";

import AuthApi from "../../../api/auth";
import { useHistory } from "react-router-dom";

function ResetPwd() {
  //  const history = useHistory();
  const [password, setPassword] = useState("");
  const [cfmPassword, setCfmPassword] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [error, setError] = useState({});
  const { uid, token, timestamp } = useParams();
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);
  const [confrmVisibility, setConfirmVisibility] = useState(false);

  const visibilityChange = () => {
    //alert("changed the visibility");
    setVisibility(!visibility);
  };

  const visibilityConfrmChange = () => {
    //alert("changed the visibility");
    setConfirmVisibility(!confrmVisibility);
  };

  // Log or use uid and token as needed
  console.log("UID:", uid);
  console.log("Token:", token);
  console.log("Timestamp:", timestamp);

  const submit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (password === "") {
      return setError({ message: "You must enter your new password.", color: "red" });
    }
    if (cfmPassword === "") {
      return setError({ message: "You must enter confirm password", color: "red" });
    }
    if (cfmPassword !== password) {
      return setError({ message: "New password and Confirm password must match.", color: "red" });
    }
    setButtonText("Updating...");

    try {
      let response = await AuthApi.ResetCnfrmpwd({
        new_password: password,
        uid,
        token,
        timestamp,
      });
      console.log("After the response");
      if (response.data && response.data.success === true) {
        setError({ message: response.data.msg, color: "green" });
      }
      localStorage.removeItem("user");
      history.push({
        pathname: "/authentication/sign-in",
        state: { key: "Password changed successfully. Login with new password!!!" },
      });
    } catch (err) {
      console.log("inside the catch block");
      console.log(err);
      if (typeof err.response !== "undefined" && err.response.status == 400) {
        setError({ message: err.response.data.msg, color: "red" });
      } else if (typeof err.response !== "undefined" && err.response.status == 403) {
        localStorage.removeItem("user");
        history.push("/authentication/sign-in");
      }
    }
    setButtonText("Submit");
  };

  return (
    <CoverLayout
      title="Reset your password"
      description="Enter your new password."
      image={logo_full}
      bg_image={image_bg}
      points={`Be RISC-V Ready with Vyoma's UpTickPro`}
      points2={`Accelerate your RISC-V product to market using ready-to-deploy`}
      points3={`RISC-V Verification, Emulation, Software ecosystem solutions`}
    >
      <Card>
        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiInput
                defaultValue={password}
                type={visibility ? "text" : "password"}
                placeholder="New Password"
                withIcon={{
                  icon: visibility ? <VisibilityOff /> : <Visibility />,
                  direction: "right",
                }}
                onChange={(event) => {
                  setPassword(event.target.value);
                  setError({});
                }}
                handleIconClick={visibilityChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                defaultValue={cfmPassword}
                type={confrmVisibility ? "text" : "password"}
                placeholder="Confirm Password"
                withIcon={{
                  icon: confrmVisibility ? <VisibilityOff /> : <Visibility />,
                  direction: "right",
                }}
                onChange={(event) => {
                  setCfmPassword(event.target.value);
                  setError({});
                }}
                handleIconClick={visibilityConfrmChange}
              />
            </SuiBox>
            <SuiBox mt={2} mb={2} textAlign="center">
              <h6
                style={{
                  fontSize: ".8em",
                  color: error.color,
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {error.message}
              </h6>
            </SuiBox>
            <SuiBox mt={4} mb={1}>
              <SuiButton onClick={submit} variant="gradient" buttonColor="dark" fullWidth>
                {buttonText}
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </Card>
    </CoverLayout>
  );
}

export default ResetPwd;
