/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/
/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SendIcon from "@mui/icons-material/Send";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
//import RunCircleIcon from "@mui/icons-material/RunCircle";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiAvatar from "components/SuiAvatar";
//import SuiBadge from "components/SuiBadge";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";

// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import Projects from "layouts/dashboard/components/Projects";
import { useState, useEffect } from "react";
import { useAuth } from "../../auth-context/auth.context";
import AuthApi from "../../api/auth";
import { useHistory } from "react-router-dom";
import image from "assets/images/cclass-img.png";
import styles from "layouts/dashboard/components/Projects/styles";
import cclassImg from "assets/images/cclass-img.png";
import Table from "examples/Table";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function ProjectDesign({ name }) {
  return (
    <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <SuiBox mr={2}>
        <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SuiBox>
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="button" fontWeight="medium">
          {name}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

function File_design({ image, name }) {
  return (
    <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <SuiBox mr={2}>
        <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SuiBox>
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="button" fontWeight="medium">
          {name}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

//function Author({ image, name, email }) {
//  return (
//    <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
//      <SuiBox mr={2}>
//        <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
//      </SuiBox>
//      <SuiBox display="flex" flexDirection="column">
//        <SuiTypography variant="button" fontWeight="medium">
//          {name}
//        </SuiTypography>
//        <SuiTypography variant="caption" textColor="secondary">
//          {email}
//        </SuiTypography>
//      </SuiBox>
//    </SuiBox>
//  );
//}

function Dashboard() {
  const [projectList, setprojectList] = useState([]);
  const [project_colsList, setproject_colsList] = useState([]);
  const [inProgress, setInprogress] = useState({});
  const [devInprogress, setDevInprogress] = useState({});
  const [devDelete, setdevDelete] = useState({});
  const [triggerPipeline, settriggerPipeline] = useState({});
  const [devdownload, setDevdownload] = useState({});
  const [localdownload, setLocaldownload] = useState({});
  const [uploadArtifiact, setuploadArtifiact] = useState({});
  const [artifacts, setArtifacts] = useState([]);
  const [heading, setHeading] = useState([{}]);
  const [accessTrigger, setAccessTrigger] = useState(false);
  const { setUser } = useAuth();
  const history = useHistory();
  let { user } = useAuth();
  const classes = styles();

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  var fileDownload = require("js-file-download");
  const localDownloadFile = (event, project_id, job_id, filename) => {
    if (event) {
      event.preventDefault();
    }
    setLocaldownload({ project_id, job_id, filename });
  };

  const devDownloadFile = (event, project_code, project_id, job_id, filename) => {
    if (event) {
      event.preventDefault();
    }
    setDevdownload({ project_code, project_id, job_id, filename });
  };

  function constructRow(project, project_code, data) {
    const project_list = [];
    Object.entries(data).map(([project_name, project_value]) => {
      const row_list = [];
      const project_row = {};
      Object.entries(project_value).map(([projectid, list]) => {
        project_row["projectId"] = projectid;
        project_row["triggerPipeline"] = (
          <SuiBox key={projectid} mr={2}>
            {" "}
            <SuiButton
              variant="gradient"
              buttonColor="info"
              mr={2}
              onClick={(event) => trigger_pipeline(event, projectid)}
              style={{ textTransform: "none" }}
            >
              Trigger CI
            </SuiButton>
          </SuiBox>
        );
        list.map((item) => {
          Object.entries(item).map(([jobid, filename]) => {
            const row = {};
            row["job_id"] = jobid;
            row["projectid"] = projectid;
            row["filename"] = filename;
            row["Filename"] = <File_design image={cclassImg} name={filename} />;
            row["DevFile"] = (
              <SuiTypography
                component="a"
                href="#"
                variant="caption"
                textColor="secondary"
                fontWeight="medium"
                onClick={(event) =>
                  devDownloadFile(event, project_code, projectid, jobid, filename)
                }
              >
                <SuiButton variant="gradient" buttonColor="info" fullWidth>
                  Dev download
                </SuiButton>
              </SuiTypography>
            );
            row["LocalFile"] = (
              <SuiTypography
                component="a"
                href="#"
                variant="caption"
                textColor="secondary"
                fontWeight="medium"
                onClick={(event) => localDownloadFile(event, projectid, jobid, filename)}
              >
                <SuiButton variant="gradient" buttonColor="info" fullWidth>
                  Download
                </SuiButton>
              </SuiTypography>
            );
            return row_list.push(row);
          });
        });
      });
      project_row["projectName"] = project_name;
      project_row["projectList"] = row_list;
      project_list.push(project_row);
    });
    setArtifacts([{ name: project, project_code: project_code, artifactList: project_list }]);
  }

  const getArtifacts = (event, project_code) => {
    if (event) {
      event.preventDefault();
    }
    setInprogress({ project_code });
  };

  const delete_devContainer = (event, project_code) => {
    if (event) {
      event.preventDefault();
    }
    setdevDelete({ project_code });
  };

  const trigger_pipeline = (event, projectId) => {
    if (event) {
      event.preventDefault();
    }
    settriggerPipeline({ projectId });
  };

  const getDevContainer = (event, project_code) => {
    if (event) {
      event.preventDefault();
    }
    setDevInprogress({ project_code });
  };

  const uploadFile = (event) => {
    if (event) {
      //event.preventDefault();
    }
    //console.log("uploaded the file");
  };

  const selectFile = (event, project_code) => {
    if (event) {
      event.preventDefault();
    }
    if (event.target.files[0] !== "undefined") {
      //console.log("selectFile the file");
      setuploadArtifiact({ project_code, file: event.target.files[0] });
    }
  };

  function constructProjectList(data) {
    const permlist = data.permission_list;
    permlist.map((proj_item) => {
      const project_row = {};
      project_row["project_code"] = proj_item["codename"];
      project_row["name"] = <ProjectDesign name={proj_item["name"]} />;
      project_row["artifacts"] = (
        <SuiButton
          variant="gradient"
          buttonColor="info"
          endIcon={<SendIcon />}
          onClick={(event) => getArtifacts(event, proj_item["codename"])}
          style={{ textTransform: "none" }}
        >
          DevOps
        </SuiButton>
      );
      project_row["workspace"] = (
        <SuiTypography
          component="a"
          href="#"
          variant="caption"
          textColor="secondary"
          fontWeight="medium"
          onClick={(event) => getDevContainer(event, proj_item["codename"])}
        >
          <SuiButton variant="gradient" buttonColor="info" startIcon={<EditNoteIcon />}>
            IDE
          </SuiButton>
        </SuiTypography>
      );
      if (proj_item["devcontainer"] > 0) {
        project_row["Deleteworkspace"] = (
          <SuiTypography
            px={3}
            component="a"
            href="#"
            variant="caption"
            textColor="secondary"
            fontWeight="medium"
            onClick={(event) => delete_devContainer(event, proj_item["codename"])}
          >
            <SuiButton variant="gradient" buttonColor="info" startIcon={<DeleteIcon />}>
              Delete
            </SuiButton>
          </SuiTypography>
        );
        project_row["Upload"] = (
          <SuiButton
            component="label"
            buttonColor="info"
            variant="gradient"
            startIcon={<CloudUploadIcon />}
            onClick={(event) => uploadFile(event)}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => selectFile(event, proj_item["codename"])}
            />
          </SuiButton>
        );
      }
      setprojectList((prev) => [...prev, project_row]);
    });
  }
  useEffect(async () => {
    const job_id = localdownload.job_id;
    if (typeof localdownload.job_id !== "undefined") {
      const updtArtifacts = artifacts.map((proj_artifact) => {
        return {
          ...proj_artifact,
          artifactList: proj_artifact.artifactList.map((artifact_obj) => {
            return {
              ...artifact_obj,
              projectList: artifact_obj.projectList.map((project_obj) => {
                if (project_obj.job_id == job_id) {
                  project_obj["LocalFile"] = <CircularProgress color="secondary" />;
                  return project_obj;
                }
                return project_obj;
              }),
            };
          }),
        };
      });
      setArtifacts(updtArtifacts);

      await AuthApi.ArtifactDownload({
        projectId: localdownload.project_id,
        jobId: localdownload.job_id,
        fileName: localdownload.filename,
        user: user,
      })
        .then((resp) => {
          fileDownload(resp.data, localdownload.filename);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.status);
          console.log(err.response);
          if (typeof err.response !== "undefined" && err.response.status == 403) {
            setUser(null);
            localStorage.removeItem("user");
            history.push("/authentication/sign-in");
          }
        });
      const updtArtifacts1 = artifacts.map((proj_artifact) => {
        return {
          ...proj_artifact,
          artifactList: proj_artifact.artifactList.map((artifact_obj) => {
            return {
              ...artifact_obj,
              projectList: artifact_obj.projectList.map((project_obj) => {
                if (project_obj.job_id == job_id) {
                  project_obj["LocalFile"] = (
                    <SuiTypography
                      component="a"
                      href="#"
                      variant="caption"
                      textColor="secondary"
                      fontWeight="medium"
                      onClick={(event) =>
                        localDownloadFile(
                          event,
                          project_obj.projectid,
                          project_obj.job_id,
                          project_obj.filename
                        )
                      }
                    >
                      <SuiButton variant="gradient" buttonColor="info" fullWidth>
                        Download
                      </SuiButton>
                    </SuiTypography>
                  );
                  return project_obj;
                }
                return project_obj;
              }),
            };
          }),
        };
      });
      setArtifacts(updtArtifacts1);
    }
  }, [localdownload]);

  useEffect(async () => {
    const job_id = devdownload.job_id;
    if (typeof devdownload.job_id !== "undefined") {
      const updtArtifactsDev = artifacts.map((proj_artifact) => {
        return {
          ...proj_artifact,
          artifactList: proj_artifact.artifactList.map((artifact_obj) => {
            return {
              ...artifact_obj,
              projectList: artifact_obj.projectList.map((project_obj) => {
                if (project_obj.job_id == job_id) {
                  project_obj["DevFile"] = <CircularProgress color="secondary" />;
                  return project_obj;
                }
                return project_obj;
              }),
            };
          }),
        };
      });
      setArtifacts(updtArtifactsDev);

      await AuthApi.ArtifactDevDownload({
        project_code: devdownload.project_code,
        projectId: devdownload.project_id,
        jobId: job_id,
        fileName: devdownload.filename,
        user: user,
      }).catch((error) => {
        console.log("Error is :" + error);
        if (typeof error.response !== "undefined" && error.response.status == 403) {
          setUser(null);
          localStorage.removeItem("user");
          history.push("/authentication/sign-in");
        }
      });

      const updtArtifactsDev1 = artifacts.map((proj_artifact) => {
        return {
          ...proj_artifact,
          artifactList: proj_artifact.artifactList.map((artifact_obj) => {
            return {
              ...artifact_obj,
              projectList: artifact_obj.projectList.map((project_obj) => {
                if (project_obj.job_id == job_id) {
                  project_obj["DevFile"] = (
                    <SuiTypography
                      component="a"
                      href="#"
                      variant="caption"
                      textColor="secondary"
                      fontWeight="medium"
                      onClick={(event) =>
                        devDownloadFile(
                          event,
                          proj_artifact.project_code,
                          project_obj.projectid,
                          project_obj.job_id,
                          project_obj.filename
                        )
                      }
                    >
                      <SuiButton variant="gradient" buttonColor="info" fullWidth>
                        Dev download
                      </SuiButton>
                    </SuiTypography>
                  );
                  return project_obj;
                }
                return project_obj;
              }),
            };
          }),
        };
      });
      setArtifacts(updtArtifactsDev1);
    }
  }, [devdownload]);

  useEffect(async () => {
    const projectId = triggerPipeline.projectId;
    if (typeof projectId !== "undefined") {
      const updtArtifacts = artifacts.map((proj_artifact) => {
        return {
          ...proj_artifact,
          artifactList: proj_artifact.artifactList.map((artifact_obj) => {
            if (artifact_obj.projectId == projectId) {
              artifact_obj.triggerPipeline = <CircularProgress color="secondary" />;
            }
            return {
              ...artifact_obj,
            };
          }),
        };
      });
      setArtifacts(updtArtifacts);

      await AuthApi.PipelineTrigger({
        projectId: projectId,
        user: user,
      }).catch((error) => {
        console.log("Error is :" + error);
        if (typeof error.response !== "undefined" && error.response.status == 403) {
          setUser(null);
          localStorage.removeItem("user");
          history.push("/authentication/sign-in");
        }
      });

      const updtArtifacts1 = artifacts.map((proj_artifact) => {
        return {
          ...proj_artifact,
          artifactList: proj_artifact.artifactList.map((artifact_obj) => {
            if (artifact_obj.projectId == projectId) {
              artifact_obj.triggerPipeline = (
                <SuiBox key={projectId} mr={2}>
                  {" "}
                  <SuiButton
                    variant="gradient"
                    buttonColor="info"
                    mr={2}
                    onClick={(event) => trigger_pipeline(event, projectId)}
                    style={{ textTransform: "none" }}
                  >
                    Trigger CI
                  </SuiButton>
                </SuiBox>
              );
            }
            return {
              ...artifact_obj,
            };
          }),
        };
      });
      setArtifacts(updtArtifacts1);
    }
  }, [triggerPipeline]);

  useEffect(async () => {
    const project_code = uploadArtifiact.project_code;

    if (typeof project_code !== "undefined") {
      const formData = new FormData();
      formData.append("uploadFile", uploadArtifiact.file);
      formData.append("project_code", project_code);
      //      console.log(formData);
      const rowItem = projectList.map((item) => {
        if (item.project_code == project_code) {
          item["Upload"] = <CircularProgress color="secondary" />;
          return item;
        }
        return item;
      });
      setprojectList(rowItem);
      await AuthApi.FileUpload({
        user: user,
        formData: formData,
      })
        .then((resp) => {
          if (resp.status == 200) {
            console.log("upload the file");
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof err.response !== "undefined" && err.response.status == 403) {
            setUser(null);
            localStorage.removeItem("user");
            history.push("/authentication/sign-in");
          }
        });
      const rowItem1 = projectList.map((item) => {
        if (item.project_code == project_code) {
          item["Upload"] = (
            <SuiButton
              component="label"
              buttonColor="info"
              variant="gradient"
              startIcon={<CloudUploadIcon />}
              onClick={(event) => uploadFile(event)}
            >
              Upload file
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => selectFile(event, project_code)}
              />
            </SuiButton>
          );
          return item;
        }
        return item;
      });
      setprojectList(rowItem1);
    }
  }, [uploadArtifiact]);

  useEffect(async () => {
    const project_code = devDelete.project_code;
    if (typeof project_code !== "undefined") {
      const rowItem = projectList.map((item) => {
        if (item.project_code == project_code) {
          item["Deleteworkspace"] = <CircularProgress color="secondary" />;
          return item;
        }
        return item;
      });
      setprojectList(rowItem);
      await AuthApi.delete_devcontainer({
        perm_code: project_code,
        user: user,
      })
        .then((resp) => {
          if (resp.status == 200) {
            const rowItem1 = projectList.map((item) => {
              if (item.project_code == project_code) {
                item["Deleteworkspace"] = <VisuallyHiddenInput />;
                item["Upload"] = <VisuallyHiddenInput />;
                return item;
              } else {
                return item;
              }
            });
            setprojectList(rowItem1);
          }
        })
        .catch((err) => {
          console.log(err);
          if (typeof err.response !== "undefined" && err.response.status == 403) {
            setUser(null);
            localStorage.removeItem("user");
            history.push("/authentication/sign-in");
          }
          const rowItem2 = projectList.map((item) => {
            if (item.project_code == project_code) {
              item["Deleteworkspace"] = (
                <SuiTypography
                  px={3}
                  component="a"
                  href="#"
                  variant="caption"
                  textColor="secondary"
                  fontWeight="medium"
                  onClick={(event) => delete_devContainer(event, project_code)}
                >
                  <SuiButton variant="gradient" buttonColor="info" startIcon={<DeleteIcon />}>
                    Delete
                  </SuiButton>
                </SuiTypography>
              );
              return item;
            }
            return item;
          });
          setprojectList(rowItem2);
        });
    }
  }, [devDelete]);

  useEffect(async () => {
    const project_code = devInprogress.project_code;
    if (typeof project_code !== "undefined") {
      const rowItem = projectList.map((item) => {
        if (item.project_code == project_code) {
          item["workspace"] = <CircularProgress color="secondary" />;
          return item;
        }
        return item;
      });
      setprojectList(rowItem);
      await AuthApi.devcontainer({
        perm_code: project_code,
        user: user,
      })
        .then((resp) => {
          let url =
            "http://" +
            resp.data.host +
            "/?tkn=" +
            resp.data.token +
            "&folder=/home/vsysuser/workspace";
          window.open(url, "_blank");
          const rowItem2 = projectList.map((item) => {
            if (item.project_code == project_code) {
              item["Deleteworkspace"] = (
                <SuiTypography
                  px={3}
                  component="a"
                  href="#"
                  variant="caption"
                  textColor="secondary"
                  fontWeight="medium"
                  onClick={(event) => delete_devContainer(event, project_code)}
                >
                  <SuiButton variant="gradient" buttonColor="info" startIcon={<DeleteIcon />}>
                    Delete
                  </SuiButton>
                </SuiTypography>
              );
              item["Upload"] = (
                <SuiButton
                  component="label"
                  buttonColor="info"
                  variant="gradient"
                  startIcon={<CloudUploadIcon />}
                  onClick={(event) => uploadFile(event)}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => selectFile(event, project_code)}
                  />
                </SuiButton>
              );
              return item;
            }
            return item;
          });
          setprojectList(rowItem2);
        })
        .catch((err) => {
          console.log(err);
          if (typeof err.response !== "undefined" && err.response.status == 403) {
            setUser(null);
            localStorage.removeItem("user");
            history.push("/authentication/sign-in");
          }
        });
      const rowItem1 = projectList.map((item) => {
        if (item.project_code == project_code) {
          item["workspace"] = (
            <SuiTypography
              component="a"
              href="#"
              variant="caption"
              textColor="secondary"
              fontWeight="medium"
              onClick={(event) => getDevContainer(event, project_code)}
            >
              <SuiButton variant="gradient" buttonColor="info" startIcon={<EditNoteIcon />}>
                IDE
              </SuiButton>
            </SuiTypography>
          );
          return item;
        }
        return item;
      });
      setprojectList(rowItem1);
    }
  }, [devInprogress]);

  useEffect(async () => {
    const project_code = inProgress.project_code;
    if (typeof project_code !== "undefined") {
      const rowItem = projectList.map((item) => {
        if (item.project_code == project_code) {
          item["artifacts"] = <CircularProgress color="secondary" />;
          return item;
        }
        return item;
      });
      setprojectList(rowItem);
      try {
        let response = await AuthApi.Artifact({ perm_code: project_code, user: user });
        const project = response.data.Project;
        const project_data = response.data.project_list;
        constructRow(project, project_code, project_data);
      } catch (error) {
        console.error(error);
        if (typeof error.response !== "undefined" && error.response.status == 403) {
          setUser(null);
          localStorage.removeItem("user");
          history.push("/authentication/sign-in");
        }
      }
      const rowItem1 = projectList.map((item) => {
        if (item.project_code == project_code) {
          item["artifacts"] = (
            <SuiButton
              variant="gradient"
              buttonColor="info"
              endIcon={<SendIcon />}
              onClick={(event) => getArtifacts(event, project_code)}
              style={{ textTransform: "none" }}
            >
              DevOps
            </SuiButton>
          );
          return item;
        }
        return item;
      });
      setprojectList(rowItem1);
    }
  }, [inProgress]);

  useEffect(async () => {
    try {
      let response = await AuthApi.PermList(user);
      constructProjectList(response.data);
      if (response.data.local_permission === true) {
        setHeading([
          { name: "Filename", align: "left" },
          { name: "DevFile", align: "center" },
          { name: "LocalFile", align: "center" },
        ]);
      } else {
        setHeading([
          { name: "Filename", align: "left" },
          { name: "DevFile", align: "center" },
        ]);
      }
      //      console.log(response.data.ci_trigger_permission);
      if (response.data.uptickpro_cloud === false) {
        setproject_colsList([]);
      } else if (response.data.upload_access === true) {
        setproject_colsList([
          { name: "name", align: "left" },
          { name: "artifacts", align: "center" },
          { name: "workspace", align: "center" },
          { name: "Upload", align: "center" },
          { name: "Deleteworkspace", align: "center" },
        ]);
      } else {
        setproject_colsList([
          { name: "name", align: "left" },
          { name: "artifacts", align: "center" },
          { name: "workspace", align: "center" },
          { name: "Deleteworkspace", align: "center" },
        ]);
      }

      if (response.data.ci_trigger_permission === true) {
        setAccessTrigger(true);
      }
    } catch (error) {
      console.log("Error is :" + error);
      if (typeof error.response !== "undefined" && error.response.status == 403) {
        setUser(null);
        localStorage.removeItem("user");
        history.push("/authentication/sign-in");
      }
    }
  }, []);

  //  const arti_cols = [
  //    { name: "Filename", align: "left" },
  //    { name: "DevFile", align: "center" },
  //    { name: "LocalFile", align: "center" },
  //  ];

  const arti = artifacts.map((proj_artifact, index) => (
    <>
      <SuiBox>
        {proj_artifact.artifactList.map((artifact_obj) => (
          <SuiBox key={index} py={3}>
            <SuiBox mb={3}>
              <Card>
                <SuiBox display="flex" alignItems="center" px={5} py={3}>
                  <SuiBox mr={2}>
                    <SuiTypography variant="h6">{artifact_obj.projectName}</SuiTypography>
                  </SuiBox>
                  {accessTrigger && artifact_obj.triggerPipeline}
                </SuiBox>
                <SuiBox customClass={classes.tables_table} key={index}>
                  <Table columns={heading} rows={artifact_obj.projectList} />
                </SuiBox>
              </Card>
            </SuiBox>
          </SuiBox>
        ))}
      </SuiBox>
    </>
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiBox>
                  <SuiTypography variant="h6" gutterBottom>
                    All Projects
                  </SuiTypography>
                </SuiBox>
              </SuiBox>
              <SuiBox customClass={classes.projects_table}>
                <Table columns={project_colsList} rows={projectList} />
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <SuiBox py={3}>{arti}</SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
