/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

// @mui material components
import Grid from "@mui/material/Grid";

// @mui icons

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Footer() {
  return (
    <SuiBox component="footer" py={0} px={-2}>
      <Grid container justifyContent="center">
        <Grid item xs={10} lg={6}>
          <SuiBox display="flex" justifyContent="center" flexWrap="wrap" mb={1}>
            <SuiBox mr={{ xs: 5, lg: 7, xl: 10 }}>
              <SuiTypography
                component="a"
                href="https://vyomasystems.com"
                target="_blank"
                variant="body2"
                textColor="secondary"
              >
                Vyoma
              </SuiTypography>
            </SuiBox>
            <SuiBox mr={{ xs: 5, lg: 7, xl: 10 }}>
              <SuiTypography
                component="a"
                href="https://youtu.be/Nj02LsAnTHg"
                target="_blank"
                variant="body2"
                textColor="secondary"
              >
                UpTickPro Product Demo
              </SuiTypography>
            </SuiBox>
            <SuiBox mr={{ xs: 5, lg: 7, xl: 1 }}>
              <SuiTypography
                component="a"
                href="https://forms.zohopublic.in/admin505/form/ClientDetails/formperma/rs6p-6DBOh06Vg9ur9WfLVTUkIUabpjLV1O4k1M-88E"
                target="_blank"
                variant="body2"
                textColor="secondary"
              >
                Contact Us
              </SuiTypography>
            </SuiBox>
          </SuiBox>
        </Grid>
        <Grid item xs={12} lg={8} className="text-center">
          <SuiTypography variant="body2" textColor="secondary">
            &copy; 2021-2024 Vyoma Systems Private Limited. All Rights Reserved.
          </SuiTypography>
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Footer;
