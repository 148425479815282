/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState } from "react";
// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { useHistory } from "react-router-dom";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useAuth } from "../../auth-context/auth.context";
import AuthApi from "../../api/auth";

// Overview page components
//import PlatformSettings from "layouts/profile/components/PlatformSettings";

function Analysis() {
  const [username, setUsername] = useState("");
  const [timeinterval, setTimeinterval] = useState("");
  const [error, setError] = useState({});
  const [buttonText, setButtonText] = useState("Submit");
  let { user } = useAuth();
  const { setUser } = useAuth();
  const history = useHistory();

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    //console.log("Button is pressed");
    if (username === "") {
      return setError({ message: "You must enter a username.", color: "red" });
    }
    if (timeinterval === "") {
      return setError({ message: "You must enter time interval[eg, 15m, 30m, 1h]", color: "red" });
    }

    setButtonText("Analysis...");
    try {
      let response = await AuthApi.Analysis({
        name: username,
        time: timeinterval,
        user,
      });
      if (response.data && response.data.success === true) {
        setError({
          sessions: response.data.No_of_sessions,
          duration: response.data.Total_durations,
          color: "green",
        });
      }
      if (response.data && response.data.success === false) {
        setError({ message: response.data.message, color: "red" });
      }
    } catch (err) {
      console.log(err);
      if (typeof err.response !== "undefined" && err.response.status == 400) {
        setError({ message: err.response.data.message, color: "red" });
      } else if (typeof err.response !== "undefined" && err.response.status == 403) {
        setUser(null);
        localStorage.removeItem("user");
        history.push("/authentication/sign-in");
      }
    }
    setButtonText("Submit");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox mt={5} mb={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} md={6} xl={4}>
            <Card>
              <SuiBox pt={2} px={2}>
                <SuiTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                  User Analysis
                </SuiTypography>
              </SuiBox>
              <SuiBox pt={3} pb={3} px={3}>
                <SuiBox component="form" role="form">
                  <SuiBox mb={2}>
                    <SuiInput
                      defaultValue={username}
                      placeholder="user name"
                      onChange={(event) => {
                        setUsername(event.target.value);
                        setError({});
                      }}
                    />
                  </SuiBox>
                  <SuiBox mb={2}>
                    <SuiInput
                      defaultValue={timeinterval}
                      placeholder="time"
                      onChange={(event) => {
                        setTimeinterval(event.target.value);
                        setError({});
                      }}
                    />
                  </SuiBox>
                  <SuiBox mb={2} textAlign="center">
                    <h6
                      style={{
                        fontSize: ".8em",
                        color: error.color,
                        textAlign: "center",
                        fontWeight: 400,
                        transition: ".2s all",
                      }}
                    >
                      {error.message}
                    </h6>
                    <h6
                      style={{
                        fontSize: ".8em",
                        color: error.color,
                        textAlign: "center",
                        fontWeight: 400,
                        transition: ".2s all",
                      }}
                    >
                      No of sessions : {error.sessions}
                    </h6>
                    <h6
                      style={{
                        fontSize: ".8em",
                        color: error.color,
                        textAlign: "center",
                        fontWeight: 400,
                        transition: ".2s all",
                      }}
                    >
                      Duration in seconds: {error.duration}
                    </h6>
                  </SuiBox>
                  <SuiBox mt={4} mb={1}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="dark"
                      fullWidth
                      onClick={handleSubmit}
                    >
                      {buttonText}
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analysis;
